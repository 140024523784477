var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

(function () {
  // This is for grouping buttons into a bar
  // takes an array of `L.easyButton`s and
  // then the usual `.addTo(map)`
  L.Control.EasyBar = L.Control.extend({
    options: {
      position: "topleft",
      // part of leaflet's defaults
      id: null,
      // an id to tag the Bar with
      leafletClasses: true // use leaflet classes?

    },
    initialize: function (buttons, options) {
      if (options) {
        L.Util.setOptions(this || _global, options);
      }

      this._buildContainer();

      (this || _global)._buttons = [];

      for (var i = 0; i < buttons.length; i++) {
        buttons[i]._bar = this || _global;
        buttons[i]._container = buttons[i].button;

        (this || _global)._buttons.push(buttons[i]);

        (this || _global).container.appendChild(buttons[i].button);
      }
    },
    _buildContainer: function () {
      (this || _global)._container = (this || _global).container = L.DomUtil.create("div", "");
      (this || _global).options.leafletClasses && L.DomUtil.addClass((this || _global).container, "leaflet-bar easy-button-container leaflet-control");
      (this || _global).options.id && ((this || _global).container.id = (this || _global).options.id);
    },
    enable: function () {
      L.DomUtil.addClass((this || _global).container, "enabled");
      L.DomUtil.removeClass((this || _global).container, "disabled");

      (this || _global).container.setAttribute("aria-hidden", "false");

      return this || _global;
    },
    disable: function () {
      L.DomUtil.addClass((this || _global).container, "disabled");
      L.DomUtil.removeClass((this || _global).container, "enabled");

      (this || _global).container.setAttribute("aria-hidden", "true");

      return this || _global;
    },
    onAdd: function () {
      return (this || _global).container;
    },
    addTo: function (map) {
      (this || _global)._map = map;

      for (var i = 0; i < (this || _global)._buttons.length; i++) {
        (this || _global)._buttons[i]._map = map;
      }

      var container = (this || _global)._container = this.onAdd(map),
          pos = this.getPosition(),
          corner = map._controlCorners[pos];
      L.DomUtil.addClass(container, "leaflet-control");

      if (pos.indexOf("bottom") !== -1) {
        corner.insertBefore(container, corner.firstChild);
      } else {
        corner.appendChild(container);
      }

      return this || _global;
    }
  });

  L.easyBar = function () {
    var args = [L.Control.EasyBar];

    for (var i = 0; i < arguments.length; i++) {
      args.push(arguments[i]);
    }

    return new (Function.prototype.bind.apply(L.Control.EasyBar, args))();
  }; // L.EasyButton is the actual buttons
  // can be called without being grouped into a bar


  L.Control.EasyButton = L.Control.extend({
    options: {
      position: "topleft",
      // part of leaflet's defaults
      id: null,
      // an id to tag the button with
      type: "replace",
      // [(replace|animate)]
      // replace swaps out elements
      // animate changes classes with all elements inserted
      states: [],
      // state names look like this
      // {
      //   stateName: 'untracked',
      //   onClick: function(){ handle_nav_manually(); };
      //   title: 'click to make inactive',
      //   icon: 'fa-circle',    // wrapped with <a>
      // }
      leafletClasses: true,
      // use leaflet styles for the button
      tagName: "button"
    },
    initialize: function (icon, onClick, title, id) {
      // clear the states manually
      (this || _global).options.states = []; // add id to options

      if (id != null) {
        (this || _global).options.id = id;
      } // storage between state functions


      (this || _global).storage = {}; // is the last item an object?

      if (typeof arguments[arguments.length - 1] === "object") {
        // if so, it should be the options
        L.Util.setOptions(this || _global, arguments[arguments.length - 1]);
      } // if there aren't any states in options
      // use the early params


      if ((this || _global).options.states.length === 0 && typeof icon === "string" && typeof onClick === "function") {
        // turn the options object into a state
        (this || _global).options.states.push({
          icon: icon,
          onClick: onClick,
          title: typeof title === "string" ? title : ""
        });
      } // curate and move user's states into
      // the _states for internal use


      (this || _global)._states = [];

      for (var i = 0; i < (this || _global).options.states.length; i++) {
        (this || _global)._states.push(new State((this || _global).options.states[i], this || _global));
      }

      this._buildButton();

      this._activateState((this || _global)._states[0]);
    },
    _buildButton: function () {
      (this || _global).button = L.DomUtil.create((this || _global).options.tagName, "");

      if ((this || _global).options.tagName === "button") {
        (this || _global).button.setAttribute("type", "button");
      }

      if ((this || _global).options.id) {
        (this || _global).button.id = (this || _global).options.id;
      }

      if ((this || _global).options.leafletClasses) {
        L.DomUtil.addClass((this || _global).button, "easy-button-button leaflet-bar-part leaflet-interactive");
      } // don't let double clicks and mousedown get to the map


      L.DomEvent.addListener((this || _global).button, "dblclick", L.DomEvent.stop);
      L.DomEvent.addListener((this || _global).button, "mousedown", L.DomEvent.stop);
      L.DomEvent.addListener((this || _global).button, "mouseup", L.DomEvent.stop); // take care of normal clicks

      L.DomEvent.addListener((this || _global).button, "click", function (e) {
        L.DomEvent.stop(e);

        (this || _global)._currentState.onClick(this || _global, (this || _global)._map ? (this || _global)._map : null);

        (this || _global)._map && (this || _global)._map.getContainer().focus();
      }, this || _global); // prep the contents of the control

      if ((this || _global).options.type == "replace") {
        (this || _global).button.appendChild((this || _global)._currentState.icon);
      } else {
        for (var i = 0; i < (this || _global)._states.length; i++) {
          (this || _global).button.appendChild((this || _global)._states[i].icon);
        }
      }
    },
    _currentState: {
      // placeholder content
      stateName: "unnamed",
      icon: function () {
        return document.createElement("span");
      }()
    },
    _states: null,
    // populated on init
    state: function (newState) {
      // when called with no args, it's a getter
      if (arguments.length === 0) {
        return (this || _global)._currentState.stateName;
      } // activate by name


      if (typeof newState == "string") {
        this._activateStateNamed(newState); // activate by index

      } else if (typeof newState == "number") {
        this._activateState((this || _global)._states[newState]);
      }

      return this || _global;
    },
    _activateStateNamed: function (stateName) {
      for (var i = 0; i < (this || _global)._states.length; i++) {
        if ((this || _global)._states[i].stateName == stateName) {
          this._activateState((this || _global)._states[i]);
        }
      }
    },
    _activateState: function (newState) {
      if (newState === (this || _global)._currentState) {
        // don't touch the dom if it'll just be the same after
        return;
      } else {
        // swap out elements... if you're into that kind of thing
        if ((this || _global).options.type == "replace") {
          (this || _global).button.appendChild(newState.icon);

          (this || _global).button.removeChild((this || _global)._currentState.icon);
        }

        if (newState.title) {
          (this || _global).button.title = newState.title;
        } else {
          (this || _global).button.removeAttribute("title");
        } // update classes for animations


        for (var i = 0; i < (this || _global)._states.length; i++) {
          L.DomUtil.removeClass((this || _global)._states[i].icon, (this || _global)._currentState.stateName + "-active");
          L.DomUtil.addClass((this || _global)._states[i].icon, newState.stateName + "-active");
        } // update classes for animations


        L.DomUtil.removeClass((this || _global).button, (this || _global)._currentState.stateName + "-active");
        L.DomUtil.addClass((this || _global).button, newState.stateName + "-active"); // update the record

        (this || _global)._currentState = newState;
      }
    },
    enable: function () {
      L.DomUtil.addClass((this || _global).button, "enabled");
      L.DomUtil.removeClass((this || _global).button, "disabled");

      (this || _global).button.setAttribute("aria-hidden", "false");

      return this || _global;
    },
    disable: function () {
      L.DomUtil.addClass((this || _global).button, "disabled");
      L.DomUtil.removeClass((this || _global).button, "enabled");

      (this || _global).button.setAttribute("aria-hidden", "true");

      return this || _global;
    },
    onAdd: function (map) {
      var bar = L.easyBar([this || _global], {
        position: (this || _global).options.position,
        leafletClasses: (this || _global).options.leafletClasses
      });
      (this || _global)._anonymousBar = bar;
      (this || _global)._container = bar.container;
      return (this || _global)._anonymousBar.container;
    },
    removeFrom: function (map) {
      if ((this || _global)._map === map) this.remove();
      return this || _global;
    }
  });

  L.easyButton = function ()
  /* args will pass automatically */
  {
    var args = Array.prototype.concat.apply([L.Control.EasyButton], arguments);
    return new (Function.prototype.bind.apply(L.Control.EasyButton, args))();
  };
  /*************************
   *
   * util functions
   *
   *************************/
  // constructor for states so only curated
  // states end up getting called


  function State(template, easyButton) {
    (this || _global).title = template.title;
    (this || _global).stateName = template.stateName ? template.stateName : "unnamed-state"; // build the wrapper

    (this || _global).icon = L.DomUtil.create("span", "");
    L.DomUtil.addClass((this || _global).icon, "button-state state-" + (this || _global).stateName.replace(/(^\s*|\s*$)/g, ""));
    (this || _global).icon.innerHTML = buildIcon(template.icon);
    (this || _global).onClick = L.Util.bind(template.onClick ? template.onClick : function () {}, easyButton);
  }

  function buildIcon(ambiguousIconString) {
    var tmpIcon; // does this look like html? (i.e. not a class)

    if (ambiguousIconString.match(/[&;=<>"']/)) {
      // if so, the user should have put in html
      // so move forward as such
      tmpIcon = ambiguousIconString; // then it wasn't html, so
      // it's a class list, figure out what kind
    } else {
      ambiguousIconString = ambiguousIconString.replace(/(^\s*|\s*$)/g, "");
      tmpIcon = L.DomUtil.create("span", "");

      if (ambiguousIconString.indexOf("fa-") === 0) {
        L.DomUtil.addClass(tmpIcon, "fa " + ambiguousIconString);
      } else if (ambiguousIconString.indexOf("glyphicon-") === 0) {
        L.DomUtil.addClass(tmpIcon, "glyphicon " + ambiguousIconString);
      } else {
        L.DomUtil.addClass(tmpIcon,
        /*rollwithit*/
        ambiguousIconString);
      } // make this a string so that it's easy to set innerHTML below


      tmpIcon = tmpIcon.outerHTML;
    }

    return tmpIcon;
  }
})();

export default {};